<template>
    <div class="JournalContent">
        <hzqing-vue-timeline
                timelineColor="#5bbcd5"
                timeContentColor="#fff"
                :dataList="data"
        ></hzqing-vue-timeline>
    </div>
</template>

<script>
    export default {
        name: "JournalContent",
        data(){
            return{
                data:[]
            }
        },
        methods:{
            async findJonrual(){
                const res = await this.$home.findJonrual(null)
                this.data = res.data
            }
        },
        created() {
            this.findJonrual()
            this.$store.commit("updateRotation",false)
            /* 隐藏左侧 */
            this.$store.commit("updateLeftShow",true)
        }
    }
</script>

<style scoped>

</style>
